<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else class="my-4">
      <h4 class="text-center my-3">Мои курсы (летний семестр)</h4>

      <!-- list of courses -->
      <div>
        <DataTable :value="teacherSummer.courses" :paginator="true" :rows="10"
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                   :rowsPerPageOptions="[10,20,50]"
                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" stripedRows
                   responsiveLayout="scroll">

          <Column field="course_name" header="Название курса">
            <template #body="{data}">
              <div v-if="data.mdl_course_id">
                <a :href="`https://moodle.uib.kz/course/view.php?id=${data.mdl_course_id}`" target="_blank">
                  {{data.name}}
                </a>
              </div>
              <div v-else>
                {{data.name}}
              </div>
            </template>
          </Column>

          <!-- import syllabus column -->
          <Column header="Силлабус">
            <template #body="{data}">
              <div v-if="data.mdl_course_id">
                <div v-if="data.syllabus_status == 0">
                  <Button label="Импортировать" @click="openImportSyllabusModal(data.id, data.mdl_course_id)"/>
                </div>
                <div v-else>
                  <span class="text-center">Силлабус уже импортирован</span>
                </div>
              </div>
              <div v-else>
                Импорт не нужен
              </div>
            </template>
          </Column>
          <!-- end import syllabus column -->

          <!-- actions -->
          <Column header="Действие">
            <template #body="{data}">
              <ul>
                <li>
                  <router-link :to="`/teacher/summer/grades?summer_course_id=${data.id}`" target="_blank">
                    Оценки
                  </router-link>
                </li>
              </ul>
            </template>
          </Column>
          <!-- end actions -->

        </DataTable>
      </div>
      <!-- end list of courses -->


      <!-- import syllabus dialog -->
      <Dialog header="Импорт силлабуса" v-model:visible="displayImportSyllabus"
              :style="{width: '100%', maxWidth: '600px'}"
              :modal="true">
        <div>
          <div class="form-group row my-3">
            <label class="col-md-3 col-form-label">Силлабус</label>
            <div class="col-md-9">
              <select class="form-control form-select" v-model="syllabusId">
                <option
                  v-for="(item, index) in syllabus_form.syllabusInfos"
                  :value="item.id"
                  :key="index">
                  {{item?.educationDiscipline?.name}} - {{item?.evaluationOption?.name}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <template #footer>
          <Button label="Отмена" @click="closeImportSyllabusModal" class="p-button-text"/>
          <Button :label="btnImportSyllabusStatus?'Импортирование':'Импортировать'"
                  @click="importSyllabus" autofocus
                  :disabled="!syllabusId||btnImportSyllabusStatus"/>
        </template>
      </Dialog>
      <!-- end import syllabus dialog -->

    </div>
  </div>
</template>


<script>
  import {mapGetters, mapActions, mapMutations, mapState} from "vuex"

  export default {
    name: "TeacherSummerCourses",
    data() {
      return {
        loading: true,
        displayImportSyllabus: false,
        putSyllabusStatusData: {},
        syllabusId: 0,
        btnImportSyllabusStatus: false
      }
    },
    computed: {
      ...mapState('teacherSummer', ['teacherSummer']),
      ...mapState('syllabus', ['syllabus_form']),
    },

    methods: {
      ...mapActions('teacherSummer', ['GET_SUMMER_COURSES_BY_TEACHER', 'POST_IMPORT_SYLLABUS', 'PUT_SUMMER_COURSE_SYLLABUS_STATUS']),
      ...mapActions('syllabus', ['GET_SYLLABUS_DATA', 'GET_CHECK_SYLLABUS_CONTENT_DATA']),
      openImportSyllabusModal(courseId, mdlCourseId) {
        this.putSyllabusStatusData = {courseId, mdlCourseId}
        this.syllabusId = 0
        this.displayImportSyllabus = true
      },
      closeImportSyllabusModal() {
        this.displayImportSyllabus = false
      },
      async importSyllabus() {
        await this.GET_CHECK_SYLLABUS_CONTENT_DATA(this.syllabusId)
        if (!this.syllabus_form.checkSyllabusContentData) {
          this.$error({text: 'Содержание дисциплины не заполнено'})
          return
        }

        this.btnImportSyllabusStatus = true
        const res = await this.POST_IMPORT_SYLLABUS({
          mdl_course_id: this.putSyllabusStatusData.mdlCourseId,
          syllabus_id: this.syllabusId
        })

        if (res == true) {
          await this.PUT_SUMMER_COURSE_SYLLABUS_STATUS({id: this.putSyllabusStatusData.courseId, syllabus_status: 1});
          await this.GET_SUMMER_COURSES_BY_TEACHER()
          this.closeImportSyllabusModal()
          this.$message({title: 'Импортирование', text: 'Силлабус успешно импортирован'});
        } else {
          this.$error({title: 'Импортирование', text: 'Произошла ошибка'})
        }
        this.btnImportSyllabusStatus = false
      }

    },
    async mounted() {
      await this.GET_SUMMER_COURSES_BY_TEACHER()
      await this.GET_SYLLABUS_DATA()
      this.loading = false
    }
  }
</script>

<style scoped>

</style>